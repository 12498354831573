@import "../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../node_modules/@syncfusion/ej2-buttons/styles/material.css";
@import "../node_modules/@syncfusion/ej2-inputs/styles/material.css";
@import "../node_modules/@syncfusion/ej2-popups/styles/material.css";
@import "../node_modules/@syncfusion/ej2-lists/styles/material.css";
@import "../node_modules/@syncfusion/ej2-navigations/styles/material.css";
@import "../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css";
@import "../node_modules/@syncfusion/ej2-dropdowns/styles/material.css";
@import "../node_modules/@syncfusion/ej2-react-documenteditor/styles/material.css";
.doc-error {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #0b0b0b;
  display: grid;
  place-items: center;
  color: white;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: 800;
}
.download-button {
  display: flex;
  justify-content: center;
  margin-bottom: 2px;
}
.download-button button {
  background-color: #0b0b0b;
  font-weight: 700;
  color: white;
  position: absolute;
  z-index: 1;
  border-radius: 12px;
  border: 0px;
  padding: 10px;
  bottom: 0;
  cursor: pointer;
}
.root-container {
  display: flex;
  /* justify-content: flex-start;
  align-items: flex-start; */
}

.index-100{
  z-index: 100 !important;
  position: absolute;
  left: 147.986px;
}

.m-r-630{
  margin-right:630px !important
}
/*.sidebar {
  height: 100vh;
  !* width: 10%; *!
  !* width: 100%; *!
  min-width: 200px;
  background-color: var(--primary);
}
.sidebar img {
  margin-top: 61px;
  width: 220px;
  height: 90%;
}
.head-container {
  display: flex;
  width: 80%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.header {
  width: 96%;
  height: 8vh;
  background-color: var(--primary);
}
.header img {
  width: 100%;
  z-index: 12;
}
*/